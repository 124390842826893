import produce from "immer";

import { getFirstDayOfCurrentMonth } from "../../utils/datepicker";

export const initialState = {
    ldasReport: {
        data: {},
        filters: {
            from: getFirstDayOfCurrentMonth(),
            to: new Date().toISOString().split("T")[0],
        }
    },
    refinanceAndPurchase: {},
    ldasQueueSegment: {
        data: {},
        filters: {
            queueOrder : 10
        }
    }
};

export const LDAS_REPORTS_ACTION_TYPES = Object.freeze({
    FETCHING_REFINANCE_AND_PURCHASE_DATA: "FETCHING_REFINANCE_AND_PURCHASE_DATA",
    CLEAR_LDAS_DATA: "CLEAR_LDAS_DATA",
    CLEAR_REFINANCE_AND_PURCHASE_DATA: "CLEAR_REFINANCE_AND_PURCHASE_DATA",

    FETCH_LEAD_DISTRIBUTION_REPORT: "FETCH_LEAD_DISTRIBUTION_REPORT",
    SET_LEAD_DISTRIBUTION_FILTER: "SET_LEAD_DISTRIBUTION_FILTER",

    FETCH_QUEUE_SEGMENT_REPORT: "FETCH_QUEUE_SEGMENT_REPORT",
    FETCH_RECENT_BROKERS_LEAD_REPORT: "FETCH_RECENT_BROKERS_LEAD_REPORT"
});

export const ldasReportsReducer = produce((draft, action) => {
    switch(action.type) {
        // Refinance And Purchase Data
        case LDAS_REPORTS_ACTION_TYPES.FETCHING_REFINANCE_AND_PURCHASE_DATA:
            draft.refinanceAndPurchase = {
                ...action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case LDAS_REPORTS_ACTION_TYPES.CLEAR_REFINANCE_AND_PURCHASE_DATA:
            draft.refinanceAndPurchase = null;
            return draft;

        // LDAS Data
        case LDAS_REPORTS_ACTION_TYPES.FETCH_LEAD_DISTRIBUTION_REPORT:
            draft.ldasReport = {
                ...draft.ldasReport,
                data: action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case LDAS_REPORTS_ACTION_TYPES.CLEAR_LDAS_DATA:
            draft.ldasReport = null;
            return draft;

        case LDAS_REPORTS_ACTION_TYPES.SET_LEAD_DISTRIBUTION_FILTER:
            draft.ldasReport = {
                ...draft.ldasReport,
                filters: {
                    ...draft.ldasReport?.filters,
                    ...action.payload
                }
            };
            return draft;

        // LDAS QUEUE SEGMENT Data for Brokers
        case LDAS_REPORTS_ACTION_TYPES.FETCH_QUEUE_SEGMENT_REPORT:
            draft.ldasQueueSegment = {
                ...draft.ldasQueueSegment,
                data: action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        case LDAS_REPORTS_ACTION_TYPES.FETCH_RECENT_BROKERS_LEAD_REPORT:
            draft.recentBrokerLeads = {
                ...draft.recentBrokerLeads,
                data: action.payload,
                stateCreatedAt: new Date()
            };
            return draft;

        default:
            return draft;
    }
}, initialState);
