import produce from "immer";


export const initialState = {
    selectedOptionSD: [] // selected Options for Secondary Dimension
};

export const TECHNOLOGY_ACTION_TYPES = Object.freeze({
    SET_SELECTED_OPTION_SD: "SET_SELECTED_OPTION_SD"
});

// This reducer is responsible for storing the dropdown options for secondary dimension
export const technologySecondaryDimensionReducer = produce((draft, action) => {
    switch(action.type) {
        case TECHNOLOGY_ACTION_TYPES.SET_SELECTED_OPTION_SD:
            draft.selectedOptionSD = action.payload;
            return draft;
        default:
            return draft;
    }
}, initialState);
